.mfac {
  &__wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__logo {
    margin-bottom: 100px;
  }

  &__button {
    margin-top: 15px;
  }

  &__info {
    width: 620px;
    background-color: rgba(101, 96, 224, 0.1);
    border-radius: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px;
  }
  &__input {
    padding: 14px 14px;
    box-sizing: content-box;
    border: 1px solid #bfc3cf;
    border-radius: 8px;
    margin: 0 7px;
    outline: none;

    &:not(:placeholder-shown) {
      border: 1px solid #7236cd;
    }
    &::selection {
      display: none;
    }
  }

  &__code {
    color: #7236cd;
    font-style: bold;
    font-size: 26px;
    margin-bottom: 10px;
  }
  &__desc {
    text-align: center;
    line-height: 21px;
  }
  &__error {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }
}
