.stat-item {
  &__wrapper {
    display: flex;
    width: 40%;
    border-radius: 16px;
    height: 40px;
    background-color: #f7f7fd;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px;
    margin-right: 30px;
    height: 168px;
  }

  &__balance {
    font-family: "Gotham Pro Bold";
    font-weight: 400;
    font-size: 50px;
    line-height: 70px;
    color: #7236cd;
    font-weight: 900;
  }

  &__title {
    line-height: 25px;
    margin-bottom: 10px;
    font-family: "Gotham Pro";
    font-weight: bold;
    font-size: 18px;
    color: #3d127d;
    margin-right: 5px;
  }

  &__button {
    border: 2px solid #7236cd;
    border-radius: 8px;
    width: 129px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__label {
      color: #7236cd;
      font-size: 14px;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}
