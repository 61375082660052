.tr-stats {
  &__wrapper {
    display: flex;
    margin-bottom: 10px;
    padding-left: 10px;
    width: fit-content;
    height: 54px;
    background-color: #f7f7fd;
    align-items: center;
    border-radius: 8px;
  }

  &__element {
    display: flex;
    color: #3d127d;
    font-size: 12px;

    &-bigger {
      margin-right: 100px;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f7f7fd;
    border-radius: 8px;
    height: 48px;
    padding: 20px;
    margin: 8px;
    cursor: pointer;
    &:hover {
      background: #f2f0fd;
    }
  }

  &__key {
    display: flex;
    color: #7236cd;
    font-weight: bold;
    font-size: 12px;
    text-decoration: underline;
  }
}
