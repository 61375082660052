.tr-filters {
  &__wrapper {
    margin-top: 30px;
    display: flex;
    margin-bottom: 50px;
    padding-left: 10px;
    width: auto;
    overflow-x: auto;
    height: 70px;
  }

  &__element {
    display: flex;
    min-width: 130px;
    color: #7236cd;
    font-weight: bold;
    font-size: 12px;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: pointer;

    &-bigger {
      margin-right: 100px;
    }

    &-active {
      background-color: #f3f3f7;
      border-radius: 8px;
      color: #3d127d;
      font-weight: bolder;
    }
  }
}
