.credit-modal {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    &-label {
      color: #3d127d;
      font-family: "Gotham Pro";
      font-weight: bolder;
      font-size: 16px;
    }
  }

  &__input {
    width: 370px;
  }

  &__button {
    width: 100%;
    margin-top: 20px;
  }

  &__close {
    cursor: pointer;
  }
}
