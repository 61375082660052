.delete-group-modal {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 64px;
    height: 64px;
    margin-top: 10px;
  }

  &__title {
    color: #3d127d;
    font-size: 24px;
    font-weight: 600;
    margin-top: 26px;
    margin-bottom: 12px;
  }

  &__subtitle {
    color: #3d127d;
    font-size: 14px;
    margin-bottom: 32px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__button {
    width: 190px;
    height: 54px;
    margin-bottom: 20px;

    &-yes {
      background: #ff3b30;
    }
  }

  &__label {
    &-yes {
      font-size: 18px;
    }
    &-no {
      font-size: 18px;
    }
  }
}
