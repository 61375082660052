.table-item-user {
  &__wrapper {
    display: flex;
    width: 100%;
    padding: 5px 0px;
    height: 50px;
    align-items: center;
    border-radius: 8px;
    padding-left: 10px;
    cursor: pointer;
  }

  &__element {
    overflow-x: auto;
    color: #3d127d;
    border-radius: 10px;

    &-big {
      width: 26%;
    }

    &-small {
      width: 12%;
    }

    &-medium {
      width: 12%;
    }

    &-type {
      position: relative;
      right: -20px;
    }
  }

  &__light {
    background-color: #fff;
  }

  &__dark {
    background-color: #f7f7fd;
  }
}
