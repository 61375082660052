.custom-select {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__label {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 12px;
    color: #3d127d;
  }

  &__error {
    color: red;
    font-size: 12px;
    height: 14px;
    margin-top: 2px;
  }
}
