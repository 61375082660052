.totals {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-bottom: 0;
    height: 95vh;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-bottom: 0;
    margin-bottom: 50px;
  }

  &__tr-wrapper {
    overflow-x: auto;
    scroll-behavior: smooth;
    margin-bottom: 20px;
  }

  &__name {
    font-family: "Gotham Pro Bold";
    font-weight: bolder;
    font-size: 32px;
    color: #3d127d;
    margin-top: 100px;
    margin-bottom: 50px;
  }

  &__label {
    margin-bottom: 10px;
    color: #3d127d;
    font-family: "Gotham Pro";
    font-weight: bolder;
    font-size: 32px;
  }

  &__loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__top-block {
    display: flex;
    flex-direction: column;
    margin-top: 68px;
    margin-bottom: 32px;
  }

  &__filter {
    height: 70px;
    &-text {
      font-size: 15px;
    }
  }

  &__volume {
    margin-top: 20px;
    color: #3d127d;
  }

  &__bold {
    font-weight: 600;
  }

  &__selector {
    &-wrapper {
      width: 200px;
      margin-bottom: 40px;
      height: 85px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-label {
      font-size: 14px;
      font-weight: 600;
      color: #3d127d;
    }
  }
}
