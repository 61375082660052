.homeheader {
  &__wrapper {
    margin-top: 20px;
    display: flex;
    height: 40px;
    align-items: center;
  }

  &__logo {
    height: 24px;
    width: 122px;
    margin-right: 50px;
  }

  &__balances {
    display: flex;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: #f7f7fd;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__limits {
    width: 100px;
  }
  &__secondary {
    min-width: 40px !important;
  }
}
