.ip-item {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 2px;
  }

  &__text {
    font-size: 12px;
    color: #3d127d;
  }
}
