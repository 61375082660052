@font-face {
  font-family: "Gotham Pro";
  src: url("./assets//fonts/GothamPro.ttf") format("woff2");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro Bold";
  src: url("./assets//fonts/GothamPro-Bold.ttf") format("woff2");

  font-weight: normal;
  font-style: normal;
}
