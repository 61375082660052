.header {
  &__wrapper {
    display: flex;
    align-items: center;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 90px;
  }

  &__back {
    position: absolute;
    left: 20px;
    cursor: pointer;
  }

  &__logo {
    cursor: pointer;
  }
}
