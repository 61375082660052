.secondary-btn {
  &__wrapper {
    display: flex;
    min-width: 282px;
    border-radius: 8px;
    height: 40px;
    background-color: #f7f7fd;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin-right: 10px;
  }

  &__smaller {
    min-width: 100px;
    justify-content: center;
    cursor: pointer;
  }

  &__single-title {
    color: #7236cd;
    font-weight: bold;
    font-size: 11px;
  }

  &__balance {
    font-family: "Gotham Pro";
    font-weight: 400;
    font-size: 11px;
    color: #3d127d;
  }

  &__title {
    font-family: "Gotham Pro";
    font-weight: bold;
    font-size: 11px;
    color: #3d127d;
    margin-right: 5px;
  }
}
