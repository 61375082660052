.user {
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 80px 0;
  }

  &__top-block {
    margin-top: 120px;
    flex-direction: column;
    display: flex;
    margin-bottom: 50px;
  }

  &__name {
    font-family: "Gotham Pro Bold";
    font-weight: bolder;
    font-size: 32px;
    color: #3d127d;
  }

  &__info-wrapper {
    display: flex;
    width: 100%;
    padding: 5px 0px;
    height: 50px;
    align-items: center;
    border-radius: 8px;
    padding-left: 10px;
    margin-bottom: 50px;
  }

  &__element {
    width: 18%;
    color: #3d127d;
    overflow-x: auto;
    &-bigger {
      margin-right: 100px;
    }
    margin-right: 10px;
  }

  &__light {
    background-color: #fff;
  }

  &__dark {
    background-color: #f7f7fd;
  }

  &__details {
    display: flex;
    margin-bottom: 50px;
  }

  &__details-ref {
    margin-bottom: 50px;
  }

  &__label {
    margin-bottom: 20px;
    color: #3d127d;
    font-family: "Gotham Pro";
    font-weight: bolder;
    font-size: 19px;
  }

  &__referred {
    margin-bottom: 20px;
    background: #000;
  }

  &__referred-by {
    margin-bottom: 20px;
    width: 100%;
    min-width: 300px;
  }

  &__referred-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__tr-wrapper {
    overflow-x: auto;
    scroll-behavior: smooth;
    margin-bottom: 20px;
  }

  &__loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__limits-wrapper {
    max-width: 290px !important;
  }

  &__under-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  &__last {
    height: 24px;
    border-radius: 12px;
    padding: 0 6px;
    background: #f7f7fd;
    min-width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;

    &__text {
      color: #3d127d;
      font-size: 12px;
    }

    &__icon {
      margin-left: 15px;
    }
  }

  &__ips {
    background-color: #f7f7fd;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 40px;
    padding: 25px;
    display: flex;
    flex-direction: column;
  }

  &__marginTop {
    margin-top: 40px;
  }

  &__ip-button {
    color: #3d127d;
    font-size: 12px;
    font-weight: 600;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
  }
}
