.monetiz-item-det {
  &__wrapper {
    display: flex;
    width: 100%;
    padding: 5px 0px;
    height: 50px;
    align-items: center;
    border-radius: 8px;
    padding-left: 10px;
    margin-bottom: 8px;
  }

  &__element {
    overflow: visible;
    width: 20%;
    color: #3d127d;
    font-size: 12px;
    &__underline {
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600;
      color: #7236cd;
    }
  }

  &__light {
    background-color: #fff;
  }

  &__dark {
    background-color: #f7f7fd;
  }

  &__button {
    width: 90px;
    height: 24px;
    margin-right: 20px;
    right: 50px;
  }

  &__label {
    font-size: 12px;
    font-weight: 600;
  }
}
