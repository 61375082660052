.mfa {
  &__wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__logo {
    margin-bottom: 100px;
  }

  &__button {
    margin-top: 15px;
  }

  &__info {
    width: 250px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 10px 10px;
  }
  &__input {
    padding: 14px 14px;
    box-sizing: content-box;
    border: 1px solid #bfc3cf;
    border-radius: 8px;
    margin: 0 7px;
    outline: none;

    &:not(:placeholder-shown) {
      border: 1px solid #7236cd;
    }
    &::selection {
      display: none;
    }
  }

  &__code {
    font-family: "Gotham Pro Bold";
    color: #000;
    font-style: bold;
    font-size: 26px;
    margin-bottom: 10px;
  }
  &__desc {
    text-align: center;
    line-height: 21px;
  }
}
