.total-picker {
  &__wrapper {
    display: flex;
    flex-direction: column;
    overflow: visible;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    &-label {
      color: #3d127d;
      font-family: "Gotham Pro";
      font-weight: bolder;
      font-size: 16px;
    }
  }

  &__close {
    cursor: pointer;
  }

  &__subtitle {
    color: #3d127d;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__inputs {
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__input {
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__label {
    font-size: 12px;
    font-weight: 600;
    color: #3d127d;
  }

  &__to {
    position: relative;
    bottom: -13px;
    color: #3d127d;
    font-weight: 600;
    font-size: 14px;
  }

  &__button {
    width: 100%;
    margin-top: 20px;
  }
}
