.login {
  &__wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__logo {
    margin-bottom: 100px;
  }
  &__log {
    margin-bottom: 20px;
    margin-top: 10px;
  }
  &__button {
    margin-top: 40px;
  }
  &__or {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bold;
  }
  &__error {
    color: #ff3897;
    font-size: 12px;
    margin-bottom: 20px;
  }
}
