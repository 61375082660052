.button {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 335px;
    height: 54px;
    background: #7834cc;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: "Gotham Pro";
    font-weight: 300;
    cursor: pointer;
  }

  &__label {
    font-weight: bold;
    font-size: 18px;

    color: #fff;
  }

  &__disabled {
    background: #ab8dd1;
  }

  &__smaller {
    height: 40px;
    width: 160px;

    &-label {
      font-size: 14px;
    }
  }

  &__loader {
    right: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__arrow {
    margin-left: 10px;
  }
}
