.legend-users-limits {
  &__wrapper {
    display: flex;
    padding: 5px 0px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding-left: 10px;
    margin-bottom: 8px;
    position: relative;
    width: calc(90%);
  }

  &__element {
    width: 16%;
    color: #3d127d;
    font-weight: bold;
    font-size: 12px;

    &-bigger {
      margin-right: 60px;
    }
  }
}
