.legend-sends-details {
  &__wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  &__element {
    width: 20%;
    color: #3d127d;
    font-weight: 600;
    font-size: 12px;

    &-bigger {
      margin-right: 60px;
    }
  }
}
