.legend-user-page {
  &__wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  &__element {
    width: 18%;
    color: #3d127d;
    font-weight: bold;
    font-size: 12px;
    margin-right: 10px;
    &-bigger {
      margin-right: 100px;
    }
  }
}
