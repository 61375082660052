.user-filters {
  &__wrapper {
    padding-bottom: 50px;
  }

  &__title {
    font-family: "Gotham Pro";
    font-weight: bold;
    font-size: 18px;
    color: #3d127d;
    margin-right: 5px;
    margin-bottom: 20px;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    max-width: 80%;
    margin-top: 30px;
  }

  &__input {
    margin-right: 10px;
  }

  &__button {
    &-reset {
      background: #fff;
      border: 1px solid #7236cd;
    }

    &-label {
      color: #7236cd;
    }
  }

  &__buttons {
    margin-top: 30px;
    max-width: 79%;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
