.input {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 12px;
    color: #3d127d;
  }

  &__field {
    color: "#3D127D";
    width: 320px;
    height: 54px;
    background: #f7f7fd;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: "Gotham Pro";
    font-weight: 300;
    padding-left: 12px;

    &::placeholder {
      color: #848ca5;
    }
  }

  &__error {
    color: red;
    font-size: 12px;
    height: 14px;
    margin-top: 2px;
  }

  &__textarea {
    resize: none;
    padding-top: 20px;
  }
}
