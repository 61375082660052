.user-type {
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  height: 24px;
  border-radius: 10px;

  &-green {
    background-color: #19d88c;
  }

  &-yellow {
    background-color: #fab700;
  }
}
