.footer {
  &__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 30px;
    height: 150px;
  }

  &__logo {
    cursor: pointer;
  }

  &__title {
    color: #c4cbdf;
    font-size: 12px;
  }
}
