.user-info-item {
  &__wrapper {
    display: flex;
    max-width: 222px;
    border-radius: 8px;
    min-height: 40px;
    background-color: #f7f7fd;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-right: 10px;
    cursor: pointer;
  }

  &__smaller {
    min-width: 140px;
    justify-content: center;
  }

  &__single-title {
    font-family: "Gotham Pro Bold";
    color: #7236cd;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    &-safari {
      font-family: "Gotham Pro";
    }
  }

  &__balance {
    font-family: "Gotham Pro";
    font-weight: 400;
    font-size: 11px;
    color: #3d127d;
  }

  &__title {
    font-family: "Gotham Pro";
    font-weight: bold;
    font-size: 11px;
    color: #3d127d;
    margin-right: 5px;
    &-multi {
      margin-right: 50px;
    }
  }

  &__multi-wrapper {
    display: flex;
  }

  &__multi-value {
    margin-right: 10px;
    font-family: "Gotham Pro";
    font-size: 11px;
    color: #7236cd;
    text-decoration: underline;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 10px;
  }

  &__multi-box {
    max-width: none;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
  }
}
