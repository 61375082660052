.actions-user {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;
  }

  &__back {
    position: absolute;
    left: 40px;
    cursor: pointer;
  }

  &__logo {
    cursor: pointer;
  }

  &__scheme {
    display: flex;
    border-radius: 8px;
    height: 40px;
    background-color: #f7f7fd;
    align-items: center;
    padding: 0 16px;
    margin-right: 10px;
    min-width: 100px;
    justify-content: center;
    cursor: pointer;
  }

  &__left {
    width: 350px;
    display: flex;
    justify-content: space-between;
  }

  &__underline {
    text-decoration: underline;
  }
  &__single-title {
    color: #7236cd;
    font-weight: 600;
    font-size: 14px;
  }
}
