.add-group-modal {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    &-label {
      color: #3d127d;
      font-family: "Gotham Pro";
      font-weight: bolder;
      font-size: 16px;
    }
  }

  &__input {
    width: 370px;

    &__small {
      width: 165px;
    }

    &__wrapper {
      width: 180px;
    }
  }

  &__button {
    width: 100%;
    margin-top: 20px;
  }

  &__close {
    cursor: pointer;
  }

  &__chapter {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #3d127d;
  }

  &__pair-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__small-label {
    color: #848ca5;
    font-weight: bolder;
  }
}
