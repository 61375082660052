.volume-details-item {
  &__wrapper {
    display: flex;
    width: 100%;
    padding: 5px 0px;
    height: 50px;
    align-items: center;
    border-radius: 8px;
    padding-left: 10px;
    margin-bottom: 8px;
    position: relative;
  }

  &__element {
    overflow: visible;
    width: 25%;
    color: #3d127d;
    font-size: 12px;

    &-bold {
      font-weight: bold;
      text-decoration: underline;
      color: #7236cd;
      cursor: pointer;
    }
  }
}
