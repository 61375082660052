.monetiz {
  &__wrapper {
    width: 100%;
  }

  &__tables {
    display: flex;
    flex-wrap: wrap;
  }

  &__selector {
    &-wrapper {
      width: 200px;
      margin-bottom: 40px;
      height: 85px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-label {
      font-size: 14px;
      font-weight: 600;
      color: #3d127d;
    }
  }

  &__items-table {
    width: 100%;
  }

  &__loader {
    width: 100%;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__controls {
    display: flex;
    min-width: 330px;
    justify-content: space-between;
  }

  &__download {
    background: #f7f7fd;
    width: 160px;
    height: 54px;
    border-radius: 4px;
    position: relative;
    color: #3d127d;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
