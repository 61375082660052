.legend-users {
  &__wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  &__element {
    color: #3d127d;
    font-weight: bold;

    &-big {
      width: 26%;
    }

    &-small {
      width: 12%;
    }
    &-medium {
      width: 12%;
      right: -20px;
      position: relative;
    }
  }
}
