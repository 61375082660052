.commands {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }

  &__filters {
    display: flex;
  }
  &__indent {
    width: 40px;
  }

  &__title {
    color: #c4cbdf;
    font-size: 12px;
  }

  &__value {
    color: #087c5c;
  }
  &__key {
    margin-right: 10px;
    color: #c02c64;
  }

  &__punctuation {
    color: #fff;
  }
  &__loader {
    height: 400px;
  }
}
