.dashboard {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 50px;
  }

  &__stats {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
  }

  &__loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
