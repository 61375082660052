.st-table {
  &__wrapper {
    color: #3d127d;
    background: #f7f7fd;
    width: 278px;
    padding: 20px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  &__item {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 10px 0;

    &__first {
      margin-bottom: 15px;
    }
    &__line {
      border-top: 1px solid #d2d2e8;
    }
  }

  &__key {
    font-size: 12px;

    &__first {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  &__value {
    font-size: 12px;
    font-weight: 600;
    position: relative;

    &__first {
      font-size: 14px;
    }
  }
  &__subValue {
    position: absolute;
    bottom: -25px;
    right: 0;
  }
  &__description {
    position: absolute;
    bottom: -7px;
    right: 0;
    font-size: 11px;
    min-width: 120px;
    font-weight: 400;
  }
  &__margin-bottom-10 {
    margin-bottom: 10px;
  }
}
