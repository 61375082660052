.st-filters {
  &__wrapper {
    margin-top: 30px;
    display: flex;
    margin-bottom: 50px;
    width: auto;
    overflow-x: auto;
    height: 70px;
  }

  &__element {
    display: flex;
    padding: 0 15px;
    color: #7236cd;
    font-weight: bold;
    font-size: 12px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: pointer;
    background: #f7f7fd;
    border-radius: 12px;

    &-bigger {
      margin-right: 100px;
    }

    &-active {
      background-color: #7236cd;
      border-radius: 8px;
      color: #fff;
      font-weight: bolder;
    }
  }
}
