.command-item {
  &__wrapper {
    display: flex;
    width: 100%;
    padding: 5px 0px;
    align-items: center;
    position: relative;
  }

  &__element {
    overflow: visible;
    width: 25%;
    color: #3d127d;
    font-size: 14px;
    &-bigger {
      width: 40%;
    }
    &-bold {
      font-weight: bold;
    }

    &-green {
      color: #05d062;
    }

    &-red {
      color: #ff3897;
    }

    &-yellow {
      color: #efac00;
    }
  }

  &__light {
    background-color: #fff;
  }

  &__dark {
    background-color: #f7f7fd;
  }

  &__button {
    width: 20px;
    height: 24px;
    justify-self: flex-end;
    position: absolute;
    right: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }

  &__label {
    font-size: 12px;
    font-weight: 600;
  }

  &__type {
    width: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    height: 24px;
    border-radius: 10px;

    &-green {
      background-color: #19d88c;
    }

    &-red {
      background-color: #ff3b30;
    }
  }
  &__value {
    color: #087c5c;
  }
  &__key {
    margin-right: 10px;
    color: #c02c64;
    line-height: 20px;
  }

  &__punctuation {
    color: #fff;
  }
  &__main-wrapper {
    border-radius: 8px;
    background-color: #f7f7fd;
    padding: 10px 0 10px 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  &__code {
    &-wrapper {
      background-color: #d8ddea;
      border-radius: 8px;
      left: -5px;
      position: relative;
      margin-top: 15px;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 41px;
      font-size: 12px;
      font-weight: 600;
      padding: 10px 10px;
      color: #3d127d;
    }
    &-container {
      background: #0d0d0d;
      padding: 10px 10px;
    }
    &-copy {
      cursor: pointer;
    }
  }
}
