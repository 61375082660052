.users-table {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title-container {
    display: flex;
    align-items: flex-end;
    height: 18px;
    margin-bottom: 40px;
    color: #3d127d;
    font-size: 20px;
  }

  &__title {
    font-family: "Gotham Pro";
    font-weight: bold;
    font-size: 20px;
    color: #3d127d;
    margin-right: 5px;
  }

  &__top-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
  }

  &__download {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__label {
      margin-left: 10px;
      color: #cad0e1;
      font-size: 12px;
      text-decoration: underline;
    }
  }
}
